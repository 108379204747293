import React from 'react';


import { Space} from 'antd';


import ImigraBoost from "@/components/quickfilingComp/dashboardProfile/imigraBoost";
import SOCKit from "@/components/quickfilingComp/dashboardProfile/socKit";
import NIWKit from "@/components/quickfilingComp/dashboardProfile/niwKit";
import EB1AKit from "@/components/quickfilingComp/dashboardProfile/eb1aKit";
import "./index.scss"



const QuickFilingPlan = () => {



    return (

        <div>
            <Space direction={"vertical"} size={'large'}>
                <ImigraBoost/>
                <SOCKit/>
                <NIWKit/>
                <EB1AKit/>
            </Space>
        </div>



    );
};
export default QuickFilingPlan;


