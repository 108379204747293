import React, {useEffect, useState} from 'react';
import {CustomerServiceOutlined, FileAddOutlined, PlusOutlined} from '@ant-design/icons';
import {Avatar, Button, Flex, FloatButton, Layout, Space} from 'antd';
import {Outlet, useNavigate} from "react-router-dom";
import Title from "antd/es/typography/Title";
import {useDispatch, useSelector} from "react-redux";
import { getQuickUserProfile, deleteProfile} from "@/apis/quick";
import {
    setBasicInfo,
    setEducations,
    setEmployments,
    setResearch,
    setUnlistedArticles,
    setMissingCitations,
    setHighlightComments,
    setBooks,
    setPatents,
    setFundingAwards,
    setHonors,
    setMediaReports,
    setPresentations,
    setReviewerExperiences,
    setProfessionalMemberships,
    setAwardFundingReviewerExperiences,
    setConsultingExperiences,
    setOpenSourceProjects,
    setWorkUsedByIndustry,
    setRequestsFor,
    setWorkUsedForCourses} from "@/store/modules/quick";

const {Content, Sider} = Layout;


const QuickFiling = () => {


    const [quickFormAuthorInfo, setQuickFormAuthorInfo] = useState(null)
    const nav = useNavigate()
    const quickForm = useSelector(state => state.quickForm);
    const dispatch = useDispatch()

    useEffect(() => {
        if (quickForm.sideProfile) {
            setQuickFormAuthorInfo(quickForm.sideProfile)
        } else {
            getQuickUserProfile().then(

                res => {
                    // console.log('getQuickUserProfile---', res)
                    if (res.code === 0) {
                        setQuickFormAuthorInfo({
                            affiliations: res.data.affiliations,
                            avatarUrl: res.data.avatarUrl,
                            firstname: res.data.firstname,
                            lastname: res.data.lastname,
                            middleName: res.data.firstname,
                        })
                        dispatch(setBasicInfo(res.data.profile?.basicInfo || {}))
                        dispatch(setEducations(res.data.profile?.educations || []))
                        dispatch(setEmployments(res.data.profile?.employments || []))
                        dispatch(setResearch(res.data.profile?.research || {}))
                        dispatch(setUnlistedArticles(res.data.profile?.unlistedArticles || []))
                        dispatch(setMissingCitations(res.data.profile?.missingCitations || []))
                        dispatch(setHighlightComments(res.data.profile?.highlightComments || []))
                        dispatch(setBooks(res.data.profile?.books || []))
                        dispatch(setPatents(res.data.profile?.patents || []))
                        dispatch(setFundingAwards(res.data.profile?.fundingAwards || []))
                        dispatch(setHonors(res.data.profile?.honors ||[]))
                        dispatch(setMediaReports(res.data.profile?.mediaReports || []))
                        dispatch(setPresentations(res.data.profile?.presentations  || []))
                        dispatch(setReviewerExperiences(res.data.profile?.reviewerExperiences || []))
                        dispatch(setProfessionalMemberships(res.data.profile?.professionalMemberships || []))
                        dispatch(setAwardFundingReviewerExperiences(res.data.profile?.awardFundingReviewerExperiences || []))
                        dispatch(setConsultingExperiences(res.data.profile?.consultingExperiences || []))
                        dispatch(setOpenSourceProjects(res.data.profile?.openSourceProjects || []))
                        dispatch(setWorkUsedByIndustry(res.data.profile?.workUsedByIndustry || []))
                        dispatch(setRequestsFor(res.data.profile?.requestsFor || []))
                        dispatch(setReviewerExperiences(res.data.profile?.reviewerExperiences || []))
                        dispatch(setWorkUsedForCourses(res.data.profile?.workUsedForCourses || []))
                    }
                }
            ).catch(error => {
                console.log(error)
            })
        }
    }, [quickForm.sideProfile])

    const onClickFloatButton = () => {

    }


    const updateAuthorInfo = () => {
        nav('/quickfiling/process/profile')
    }
    const deleteAuthorInfo = () => {
        deleteProfile().then(
            res => {
                if (res.code === 0) {
                    setQuickFormAuthorInfo(null)
                    nav("/quickfiling")
                }else{
                    throw new Error("delete user profile failed")
                }
            }
        ).catch(error => {
            console.log(error)
        })

    }

    return (

        <div>
            <div style={{backgroundColor: '#f4f4f4'}}>
                <Layout>
                    <Layout>
                        <Sider width="15%" theme={"light"}>
                            {quickFormAuthorInfo !== null ? <div className={'quickfiling-profile'}>
                                    <Flex vertical={true} align={'center'}>
                                        <Flex vertical={true} align={'center'}>
                                            <div>
                                                <Avatar
                                                    src={quickFormAuthorInfo?.avatarUrl || ""}
                                                    size={120}
                                                    shape="square"
                                                    alt={"please login"}
                                                />
                                            </div>
                                            <br/>
                                            <h2>{quickFormAuthorInfo?.firstname || "harris"}</h2>
                                            <h2>{quickFormAuthorInfo?.lastname || "trump"}</h2>
                                            <br/>
                                            <div className={'ellipsis-text-2'}
                                                 style={{maxWidth: '10rem'}}>{quickFormAuthorInfo?.affiliations || "USA"}</div>
                                        </Flex>
                                        <div style={{marginTop: 16}}>
                                            <Space direction={"vertical"} size={"large"}>
                                                <Button type="primary" style={{width: 120}} onClick={updateAuthorInfo}>Update
                                                    Profile</Button>
                                                <Button type="primary" style={{width: 120}} onClick={deleteAuthorInfo}>Delete
                                                    Profile</Button>
                                                <Button type="primary" style={{width: 120}}>Bill</Button>
                                            </Space>
                                        </div>
                                    </Flex>
                                </div> :
                                <Flex vertical={true} justify={"center"} align="center" style={{paddingTop: 75}}>
                                    <div style={{fontSize: 48}}><FileAddOutlined/></div>
                                    <div style={{width: '60%', textAlign: "center"}}>
                                        <Title level={5}>Create your first profile to get started!</Title>
                                    </div>
                                    <br/>
                                    <Button icon={<PlusOutlined/>} iconPosition={"start"} type={"primary"}
                                            href={'/quickfiling/process/profile'}> Create
                                        profile </Button>
                                </Flex>}
                        </Sider>
                        <Content>
                            <div style={{margin: '30px', minHeight: '720px'}}><Outlet/></div>
                        </Content>
                    </Layout>
                </Layout>


                <FloatButton
                    onClick={onClickFloatButton}
                    icon={<CustomerServiceOutlined style={{fontSize: '20px'}}/>}
                    type="primary"
                    shape={"square"}
                    style={{
                        insetInlineEnd: 20,
                        bottom: 20,
                        width: 45,  // 调整宽度
                        height: 45, // 调整高度
                    }}
                />
            </div>
        </div>


    );
};
export default QuickFiling;

