import React, {useEffect, useState, useContext} from 'react';
import {Button, Divider, Flex, message, Modal, Space, Spin, Table, Typography, Upload} from 'antd';
import {LoadingOutlined, UploadOutlined} from "@ant-design/icons";
import {checkMissingFile, getOSSUrl, uploadFile2OSS} from "@/apis/quick";
import {MissPaperUrlPrefix} from "@/data/data";
import {MissingFileContext} from "@/pages/quickfiling/checkMissFile";


const {Title} = Typography;


const CheckMissingFileTable = () => {

    const [cateDescList, setCateDescList] = useState([]);
    const [citedArticlesObj, setCitedArticlesObj] = useState(null);
    const [citingArticlesObj, setCitingArticlesObj] = useState(null);
    const [confirmStates, setConfirmStates] = useState({}); // 每行的忽略状态
    const [modalVisible, setModalVisible] = useState(false); // 控制 Modal 显示状态
    const [currentViewCitesId, setCurrentViewCitesId] = useState(null); // 当前 Modal 显示内容
    const [loading, setLoading] = useState(true);
    const {
        ignoreCitingStates,
        setIgnoreCitingStates,
        ignoreCitedStates,
        setIgnoreCitedStates,
        citedUpdateFileRef,
        citingUpdateFileRef,
    } = useContext(MissingFileContext);

    useEffect(() => {
        checkMissingFile().then(
            response => {
                if (response.code === 0) {
                    setCitedArticlesObj(response.data.citedArticles);
                    setCitingArticlesObj(response.data.citingArticles)
                    setCateDescList(response.data.descEntries)
                    setLoading(false)
                }
            }
        ).catch(
            err => {
                console.log(err);
            }
        )
    }, [])

    const updateRefData = (ref, newEntry) => {
        const existingIndex = ref.current.findIndex((item) => item.id === newEntry.id);
        if (existingIndex !== -1) {
            // 如果找到相同的 id，则覆盖已有记录
            ref.current[existingIndex] = newEntry;
        } else {
            // 如果没有相同的 id，则新增记录
            ref.current.push(newEntry);
        }
    };

    // 切换忽略状态
    const toggleCitedIgnore = async (id) => {
        setIgnoreCitedStates((prevStates) => ({
            ...prevStates,
            [id]: !prevStates[id], // 切换状态
        }));
    };

    const toggleCitingIgnore = async (id) => {
        setIgnoreCitingStates((prevStates) => ({
            ...prevStates,
            [id]: !prevStates[id], // 切换状态
        }));
    };

    const toggleConfirm = (key) => {
        setConfirmStates((prevStates) => ({
            ...prevStates,
            [key]: !prevStates[key], // 切换状态
        }));
    }

    const showCitations = (key) => {
        setModalVisible(true);
        setCurrentViewCitesId(key);
    }

    const viewResource = (resource_url) => {
        window.open(resource_url)
    }

    const updatePaperFile = async (options, id, articleType, cateKey) => {
        const {file, onProgress, onSuccess} = options;

        try {
            // const res = {
            //     data : {
            //         objectKey: file.name
            //     }
            // }
            const res = await getOSSUrl({
                fileName: file.name,
                checkMissing: true,
            })
            await uploadFile2OSS(res.data.preSignUrl, file, onProgress)
            const newLink = MissPaperUrlPrefix +  res.data.objectKey
            const newFileEntry = {
                id: id,
                link_url: newLink,
            };
            if (articleType === 0) {
                setCitedArticlesObj((prevData) => {
                    if (!prevData || !prevData[cateKey]) return prevData;
                    const updatedArticles = prevData[cateKey].map((article) => {
                        if (article.id === id) {
                            return {...article, fileLink: newLink};
                        }
                        return article;
                    });
                    return {
                        ...prevData,
                        [cateKey]: updatedArticles,
                    };
                });
                updateRefData(citedUpdateFileRef, newFileEntry);
            } else if (articleType === 1) {
                setCitingArticlesObj((prevData) => {
                    if (!prevData || !prevData[currentViewCitesId]) return prevData; // 防止 prevData 或指定键为空
                    const updatedArticles = prevData[currentViewCitesId].map((article) => {
                        if (article.result_id === id) {
                            return {...article, download_url: newLink}; // 修改 fileLink
                        }
                        return article;
                    });
                    return {
                        ...prevData,
                        [currentViewCitesId]: updatedArticles,
                    };
                })
                const updateNewFileEntry = {
                    ...newFileEntry,
                    citesId : currentViewCitesId
                }
                updateRefData(citingUpdateFileRef, updateNewFileEntry);
            }
            message.success(`${file.name} uploaded successfully!`);
            onSuccess(); // 通知 Upload 组件上传成功
        } catch (error) {
            console.log(error);
            message.error(`${file.name} uploaded failed!`);
        }
    }

    const getCitingColumns = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            render: (text) => <span>{text}</span>,
            ellipsis: true,
            width: "400px",
            align: "left",
        },
        {
            title: "Resource",
            dataIndex: "download_url",
            key: "download_url",
            render: (value, record, index) => (
                <Button disabled={!record.download_url} onClick={() => viewResource(record.download_url)}>View</Button>
            ),
            shouldCellUpdate: (record, prevRecord) => record.download_url !== prevRecord.download_url, // 仅当 download_url 变化时更新
            ellipsis: true,
            width: "100px",
            align: "center",
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (value, record, index) => (
                <Space size="middle">
                    <Button
                        color={"danger"}
                        variant={!ignoreCitingStates[record.result_id] ? 'dashed' : "solid"}
                        onClick={() => toggleCitingIgnore(record.result_id, 1)}
                    >
                        {ignoreCitingStates[record.result_id] ? "Undo" : "Ignore"}
                    </Button>
                    <Upload
                        disabled={ignoreCitingStates[record.result_id]}
                        name="file"
                        customRequest={(options) => updatePaperFile(options, record.result_id, 1)}
                        showUploadList={false} // 隐藏默认的文件列表
                    >
                        <Button disabled={ignoreCitingStates[record.result_id]} icon={<UploadOutlined/>}>Update</Button>
                    </Upload>
                    <Button
                        onClick={() => toggleConfirm(record.result_id)}
                        variant={"solid"}
                        color={confirmStates[record.result_id] ? 'default' : 'primary'}
                        disabled={ignoreCitingStates[record.result_id]}
                    >
                        Confirm
                    </Button>
                </Space>
            ),
            width: 250,
            align: 'center',
        },
    ]

    const getCitedColumns = (cateKey) => {
        return [
            {
                title: "Your publication",
                dataIndex: "title",
                key: "title",
                render: (text) => <span>{text}</span>,
                ellipsis: true,
                width: "400px",
                align: "left",
            },
            {
                title: "Cited Count",
                key: "count",
                dataIndex: "citedCount",
                render: (value) => (
                    <span>{value}</span>
                ),
                width: 100,
                align: "center",
            },
            {
                title: "Citations",
                key: "citations",
                render: (value, record) => (
                    <Button disabled={ignoreCitedStates[record.id] || record.citedCount === 0} type="primary"
                            onClick={() => showCitations(record.citesID)}>
                        Check
                    </Button>
                ),
                width: 100,
                align: "center",
            },
            {
                title: "Resource",
                dataIndex: "fileLink",
                key: "fileLink",
                render: (value, record, index) => (
                    <Button disabled={!record.fileLink} onClick={() => viewResource(record.fileLink)}>View</Button>
                ),
                shouldCellUpdate: (record, prevRecord) => record.fileLink !== prevRecord.fileLink, // 仅当 download_url 变化时更新
                ellipsis: true,
                width: "100px",
                align: "center",
            },
            {
                title: 'Actions',
                key: 'actions',
                render: (value, record, index) => (
                    <Space size="middle">

                        <Button
                            color={"danger"}
                            variant={!ignoreCitedStates[record.id] ? 'dashed' : "solid"}
                            onClick={() => toggleCitedIgnore(record.id, 0)}
                        >
                            {ignoreCitedStates[record.id] ? "Undo" : "Ignore"}
                        </Button>
                        <Upload
                            disabled={ignoreCitedStates[record.id]}
                            name="file"
                            customRequest={(options) => updatePaperFile(options, record.id, 0, cateKey)}
                            showUploadList={false} // 隐藏默认的文件列表
                        >
                            <Button disabled={ignoreCitedStates[record.id]} icon={<UploadOutlined/>}>Update</Button>
                        </Upload>
                        <Button
                            onClick={() => toggleConfirm(record.id)}
                            variant={"solid"}
                            color={confirmStates[record.id] ? 'default' : 'primary'}
                            disabled={ignoreCitedStates[record.id]}
                        >
                            Confirm
                        </Button>
                    </Space>
                ),
                width: 250,
                align: 'center',
            },
        ]
    }

    return (
        <div style={{marginTop: '20px'}}>
            <div style={{width: '90%'}}>

                {loading ? <Flex justify={"center"} align={"center"} style={{marginTop: '120px'}}>
                        <Spin indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 48,
                                }}
                                spin
                            />
                        }/>
                    </Flex>
                    :
                    <div>
                        {cateDescList
                            .filter((item) => item.cateKey !== "default") // 过滤掉不需要的项
                            .map((item, index) => (
                                citedArticlesObj && citedArticlesObj[item.cateKey] && <div key={index}>
                                    <Space size={"large"}>
                                        <Space size={"middle"}>
                                            <Title level={4}>Category: </Title>
                                            <Title level={4}>{item.cateKey}</Title>
                                        </Space>
                                        <Space size={"middle"}>
                                            <Title level={5}>Description: </Title>
                                            <Title level={5}>{item.cateDesc}</Title>
                                        </Space>
                                    </Space>
                                    <Table
                                        columns={getCitedColumns(item.cateKey)}
                                        showHeader={true}
                                        dataSource={citedArticlesObj[item.cateKey]}
                                        pagination={false}
                                        rowKey='id'
                                    />
                                    <Divider/>
                                </div>
                            ))}


                        <Modal
                            open={modalVisible}
                            title={"View Citation Details"}
                            onCancel={() => setModalVisible(false)}
                            footer={null}
                            width="60%" // 设置宽度
                            centered
                        >

                            {citingArticlesObj && citingArticlesObj[currentViewCitesId] &&
                                <div>
                                    <Table columns={getCitingColumns}
                                           showHeader={true}
                                           dataSource={citingArticlesObj[currentViewCitesId]}
                                           pagination={true}
                                           rowKey="result_id"
                                    />
                                </div>
                            }
                        </Modal>
                    </div>
                }
            </div>
        </div>
    )
}
export default CheckMissingFileTable;