import {request} from "@/utils";


export function deleteProfile() {
    return request({
        url: "/quickfiling/delUserProfile",
        method: "DELETE",
    })
}



export function submitProfile(data) {
    return request({
        url: "/quickfiling/createProfile",
        method: "POST",
        data: data,
        timeout: 10000,
    })
}


export function getOSSUrl(params) {
    return request({
        url: "/quickfiling/getUploadUrl",
        method: "GET",
        params: params,
    })
}


export function uploadFile2OSS(url, file, onProgress) {
    return request({
        url: url,
        method: "PUT",
        data: file,
        timeout: 10000,
        headers: {
            'Content-Type': file.type, // 确保使用正确的 Content-Type
        },
        onUploadProgress: ({total, loaded}) => {
            onProgress({percent: Math.round((loaded / total) * 100)}, file);
        },
    })
}



export function getPreviewLink  (fileName)  {
    return request({
        url: "/quickfiling/getFilePreviewUrl",
        method: "GET",
        params: {
            fileName: fileName,
        }
    })
}

export function getQuickSideProfile  ()  {
    return request({
        url: "/quickfiling/geSideProfile",
        method: "GET",
    })
}


export function getQuickUserProfile  ()  {
    return request({
        url: "/quickfiling/getUserProfile",
        method: "GET",
    })
}

export function getAchievementCategory  ()  {
    return request({
        url: "/quickfiling/getAchievementCategory",
        method: "GET",
    })
}

export function updateAchievementCategory  (data)  {
    return request({
        url: "/quickfiling/updateAchievementCategory",
        method: "POST",
        data: data,
    })
}


export function deleteCategoryByUser  ()  {
    return request({
        url: "/quickfiling/deleteCategoryByUser",
        method: "DELETE",
    })
}



export function checkMissingFile  ()  {
    return request({
        url: "/quickfiling/checkMissingFile",
        method: "GET",
        timeout: 30000
    })
}


export function ignoreArticleAndUpdateFileById  (data)  {
    return request({
        url: "/quickfiling/ignoreArticleAndUpdateFileById",
        method: "POST",
        data: data,
        timeout: 10000,
    })
}




