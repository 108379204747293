import {Button, Card, Flex, Input, message, Space, Tag, Tooltip, Typography} from "antd";
import React, {useState, useEffect, useContext} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {LinkOutlined} from "@ant-design/icons";
import {Link, useNavigate} from "react-router-dom";
import {MainStepContext} from "@/components/quickfilingComp/mainSteps";
import {getAchievementCategory, updateAchievementCategory, deleteCategoryByUser} from "@/apis/quick";
import {AchieveType2TagName} from "@/data/data";




const {Title, Paragraph} = Typography;


export const reOrderArticles = ({achievements, source, destination}) => {

    // 深拷贝 Map，确保不直接修改原始数据
    const updatedAchievements = new Map(achievements);

    // 查找源列和目标列
    // 获取源列和目标列
    const sourceColumn = updatedAchievements.get(source.droppableId);
    const destColumn = updatedAchievements.get(destination.droppableId);
    // 如果源列或目标列不存在，直接返回原始数据
    if (!sourceColumn || !destColumn) return updatedAchievements;

    // 深拷贝源列和目标列的成果列表
    const sourceArticles = Array.isArray(sourceColumn) ? [...sourceColumn] : [];
    const destArticles = Array.isArray(destColumn) ? [...destColumn] : [];

    if (source.droppableId === destination.droppableId) {
        // 如果在同一列中拖动
        const [movedItem] = sourceArticles.splice(source.index, 1);
        sourceArticles.splice(destination.index, 0, movedItem);
        // 更新当前列
        updatedAchievements.set(source.droppableId, sourceArticles);
    } else {
        // 跨列拖动
        const [movedItem] = sourceArticles.splice(source.index, 1);
        destArticles.splice(destination.index, 0, movedItem);

        // 更新源列和目标列
        updatedAchievements.set(source.droppableId, sourceArticles);
        updatedAchievements.set(destination.droppableId, destArticles);
    }
    // 返回更新后的 Map
    return updatedAchievements;
};


const CategoryColumn = ({index, dropId, column}) => {


    return (
        <Droppable droppableId={String(dropId)} key={String(index)}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                        backgroundColor: snapshot.isDraggingOver ? 'lightblue' : '#ebeff7',
                        width: '220px',
                        padding: '10px 0',
                        borderRadius: '10px',
                        minHeight: '700px'
                    }}
                >
                    {Array.isArray(column) && column.length > 0 &&column.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided, snapshot) => (
                                <Card
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                        margin: '10px 5px',
                                        ...provided.draggableProps.style,
                                    }}
                                >
                                    <Tag
                                        color={AchieveType2TagName[item.type].color}>
                                        {AchieveType2TagName[item.type].name}
                                    </Tag>
                                    <div><Link to={item.link_url}>{item.title} <LinkOutlined/></Link></div>
                                </Card>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder} {/* 确保提供 placeholder */}
                </div>
            )}
        </Droppable>
    );
};



const OrganizeResearch = () => {

    const [achievements, setAchievements] = useState(new Map(
        [
            ['default', []]
        ]
    ));
    const [achievementsDesc, setAchievementsDesc] = useState([]);
    const [newColumnTitle, setNewColumnTitle] = useState('');
    const [newColumnDescription, setNewColumnDescription] = useState('');
    // const [resetColumns, setResetColumns] = useState(initColumns);  // 保存初始状态
    const nav = useNavigate()
    const {setMainCurrent} = useContext(MainStepContext);


    const handleReset = () => {
        setAchievements((prevAchievements) => {
            const newAchievements = new Map([['default', []]]);
            for (const [key, value] of prevAchievements.entries()) {
                newAchievements.get('default').push(...value);
            }
            return newAchievements;
        });
        const newAchievementsDesc = [{cateKey: 'default', cateDesc: 'uncategorized items will be ignored'}];
        setAchievementsDesc(newAchievementsDesc);
        deleteCategoryByUser().then(
            res => {
                console.log(res);
            }
        ).catch(err => console.log(err));
    };


    // 在组件加载时从服务器获取数据，并初始化第一列
    useEffect(() => {

        getAchievementCategory().then(
            response => {
                if (response.code === 0) {
                    // 将 JSON 对象转换为 Map
                    const newAchievements = new Map(Object.entries(response.data.achievementMap));
                    setAchievements(newAchievements);
                    setAchievementsDesc(response.data.descMap)
                }
            }
        )
        setMainCurrent(1);
    }, []);


    const handleSave = async () => {

        try {
            const filteredMapStr = Object.fromEntries(achievements);
            const data = {
                categoryAchievements: filteredMapStr,
                categoryAchievementsDesc: achievementsDesc,
            }
            console.log('Saved Data:', data);
            const response = await updateAchievementCategory(data)
            if (response.code === 0) {
                console.log("save success")
                return 0;
            }else {
                return -1;
            }
        }catch (err) {
            console.error(err);
            return -1; // 失败
        }
    };


    const handleConfirm = async () => {
        const code = await handleSave(); // 等待异步保存完成
        if (code === 0) {
            // 跳转到下一步
            nav("/quickfiling/process/checkMissing");
        } else {
            message.error("save failed");
        }
    };


    const handleDeleteColumn = (columnId) => {
        // 创建一个新的 Map，并删除指定的列
        const newColumns = new Map(achievements);
        newColumns.delete(columnId); // 使用 Map 的 delete 方法删除指定的键
        setAchievements(newColumns); // 更新 state
    };

    const handleAddColumn = () => {
        if (newColumnTitle === '') {
            return
        }
        setAchievements((prev) => {
            const updatedAchievements = new Map(prev);
            updatedAchievements.set(newColumnTitle, []);
            return updatedAchievements;
        });
        setAchievementsDesc([
            ...achievementsDesc,
            {cateKey: newColumnTitle, cateDesc: newColumnDescription}
        ]);
        setNewColumnTitle('');  // 清空输入框
        setNewColumnDescription('');
    };


    const handleTitleChange = (id, newTitle) => {
        setAchievements((prev) => {
            const value = prev.get(id);
            const newAchievements = new Map(prev);
            newAchievements.delete(id);
            newAchievements.set(newTitle, value);
            return newAchievements;
        });
    };

    const handleDescriptionChange = (id, newDescription) => {
        // setAchievementsDesc((prev) => {
        //     const newAchievementsDesc = new Map(prev);
        //     newAchievementsDesc.set(id, newDescription);
        //     return newAchievementsDesc;
        // });
        setAchievementsDesc((prev) =>
            prev.map((entry) =>
                entry.cateKey === id
                    ? { ...entry, cateDesc: newDescription } // Update the matching entry
                    : entry // Keep unchanged
            )
        );
    };

    const onDragEnd = (result) => {
        const {source, destination} = result;

        // 检查是否有效拖动
        if (!destination) return;

        // 只处理文章的拖动
        const data = reOrderArticles({achievements, source, destination});
        setAchievements(data);
    };


    return (
        <div className={"organize-research"}>
            <div>
                <Flex justify={'space-between'}>
                    <Title level={4}>
                        Group your research by topic
                    </Title>
                    <Space>
                        <Button danger onClick={handleReset}>Reset</Button>
                        <Button onClick={handleSave}>Save</Button>
                        <Button type={"primary"} onClick={handleConfirm}>Confirm</Button>
                    </Space>
                </Flex>
            </div>

            <Space size={"large"} align={"start"}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Space size={'large'} align={"start"}>
                        {achievementsDesc.map((descItem, index) => {
                            return (
                                <div key={descItem.cateKey} className={'category-container'}>
                                    <div className={'title'}>
                                        <Flex align={'center'} vertical={true}>
                                            <Title level={4}
                                                   ellipsis={{rows: 1}}
                                                   editable={descItem.cateKey !== 'default' ? {onChange: (newTitle) => handleTitleChange(descItem.cateKey, newTitle)} : false}
                                                   style={{width: '100%', maxWidth: '200px', textAlign: 'center'}}
                                            >{descItem.cateKey}</Title>
                                            <Tooltip title={descItem.cateDesc}>
                                                <Paragraph ellipsis={{rows: 1}}
                                                           editable={descItem.cateKey !== 'default' ? {onChange: (newDescription) => handleDescriptionChange(descItem.cateKey, newDescription)} : false}
                                                           style={{
                                                               width: '100%',
                                                               maxWidth: '200px',
                                                               textAlign: 'center'
                                                           }}>
                                                    {descItem.cateDesc}
                                                </Paragraph>
                                            </Tooltip>
                                        </Flex>
                                    </div>
                                    <Flex justify={'space-evenly'}>
                                        <Button type={"text"} danger
                                                onClick={() => handleDeleteColumn(descItem.cateKey)}>delete</Button>
                                    </Flex>
                                    <CategoryColumn
                                        index={index}
                                        dropId={descItem.cateKey}
                                        column={achievements.get(descItem.cateKey)}
                                    />
                                </div>
                            )
                        })}
                    </Space>
                </DragDropContext>

                {/* 添加新列的输入框和按钮 */}
                <div className={'category-container'}>
                    <Flex vertical={true} align={'center'}>
                        <Title level={4}>Add Column</Title>
                        <Input
                            size={'large'}
                            type="text"
                            placeholder="New column title"
                            value={newColumnTitle}
                            onChange={(e) => setNewColumnTitle(e.target.value)}
                        />
                        <br/>
                        <Input.TextArea
                            placeholder="Column description"
                            value={newColumnDescription}
                            onChange={(e) => setNewColumnDescription(e.target.value)}
                        />
                        <br/>
                        <Button onClick={handleAddColumn} type={"primary"}>Add</Button>
                    </Flex>

                </div>
            </Space>
        </div>
    )
}

export default OrganizeResearch