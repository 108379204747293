import React, {useEffect, useState, useContext, useRef, createContext} from 'react';
import {InfoCircleOutlined} from "@ant-design/icons";
import {MainStepContext} from "@/components/quickfilingComp/mainSteps";
import {useNavigate} from "react-router-dom";
import {Button, message, Space, Typography, Flex} from 'antd';
import {ignoreArticleAndUpdateFileById,} from "@/apis/quick";

import CheckMissingFileTable from "@/components/quickfilingComp/tables/checkMissingFileTable";



const {Title} = Typography;

export const MissingFileContext = createContext();

const CheckMissFile = () => {

    const {setMainCurrent} = useContext(MainStepContext);

    const [ignoreCitingStates, setIgnoreCitingStates] = useState({}); // 每行的忽略状态
    const [ignoreCitedStates, setIgnoreCitedStates] = useState({}); // 每行的忽略状态
    const citedUpdateFileRef = useRef([]);
    const citingUpdateFileRef = useRef([]);

    const navigate = useNavigate();

    useEffect( () => {
        setMainCurrent(2);

    }, [])

    const contextValue = {
        ignoreCitingStates,
        setIgnoreCitingStates,
        ignoreCitedStates,
        setIgnoreCitedStates,
        citedUpdateFileRef,
        citingUpdateFileRef,
    };

    const generateHighlights = () => {
        // articleType: 0是被引文章，1是引用文章
        const data = {
            citedUpdateFiles: citedUpdateFileRef.current,
            citingUpdateFiles: citingUpdateFileRef.current,
            ignoreCited: Object.entries(ignoreCitedStates) // 转换为键值对数组
                .filter(([key, value]) => value) // 过滤值为 true 的键值对
                .map(([key]) => key) // 提取键
        }
        ignoreArticleAndUpdateFileById(data).then(
            response => {
                if (response.code === 0) {
                    navigate('/quickfiling/process/review')
                }else{
                    throw new Error("response.code: " + response.code)
                }
            }
        ).catch(
            err => {
                console.log(err);
                message.error("save info failed, please try again");
            }
        )

    }


    return (

        <div>
            <div className="check-missing-container">
                <div className={'title'}>
                    <Flex justify={'space-between'} align={'center'}>
                        <Space align={'baseline'}>
                            <span><InfoCircleOutlined/></span>
                            <Title level={5}> Add any missing publication or citation of your papers. Ignore the ones
                                which you don't want to include in the petition letter. </Title>
                        </Space>
                        <Button type={"primary"} onClick={generateHighlights}>Next: Generate Citation
                            Highlights</Button>
                    </Flex>
                </div>

                <br/>
                <MissingFileContext.Provider value={contextValue}>
                    <CheckMissingFileTable />
                </MissingFileContext.Provider>

            </div>
        </div>
    )

}


export default CheckMissFile